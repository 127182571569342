<template>
  <div class="">
    <clinician-program-form
      v-if="loaded"
      :action="action"
      :program="program"
      :program-types="programTypes"
      @submitProgram="submitProgram"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import ClinicianProgramForm from '@/components/clinician/programs/ClinicianProgramForm'

export default {
  name: 'ClinicianProgramFormWrapper',
  components: {
    ClinicianProgramForm
  },
  data () {
    return {
      loaded: false
    }
  },
  computed: {
    action () {
      return this.$route.params.programId ? 'update' : 'create'
    },
    ...mapGetters({
      program: 'getSingleProgram',
      programTypes: 'getProgramTypes'
    })
  },
  created () {
    this.$store.dispatch('GET_PROGRAM_TYPES')
    this.$store.dispatch('GET_PROGRAM', this.$route.params.programId).finally(() => {
      this.loaded = true
    })
  },
  methods: {
    submitProgram (program) {
      const action = this.action === 'update' ? 'UPDATE_PROGRAM' : 'CREATE_PROGRAM'
      const loader = this.$loading.show()
      const $validator = this.$validator
      this.$store.dispatch(action, program)
        .then(response => {
          if (response && response.status !== 200) {
            this.$handleApiError(response)
            Object.keys(response.data.errors).forEach(function (field) {
              $validator.errors.add(field, response.data.errors[field])
            })
          } else {
            const messageIndex = this.action === 'update' ? 'fieldUpdated' : 'fieldAdded'
            this.$toast.success({ message: this.$t(messageIndex, { name: program.name }) })
            if (program.addMeasure) {
              this.$router.push({ name: 'SettingsProgramAddMeasure', params: { programId: response.data.id }, query: { editing: this.action === 'update' } })
            } else {
              this.$router.push({ name: 'SettingsPrograms' })
            }
          }
        })
        .catch((e) => {
          this.$handleApiError(e)
        })
        .finally(() => {
          loader.hide()
        })
    }
  }
}
</script>
