<template>
  <div class="clinician-programs-add">
    <h3>{{ $t(title) }}</h3>
    <form @submit.prevent="submitProgram">
      <div class="form-group">
        <label for="programName">{{ $t('programName') }} *</label>
        <input
          id="programName"
          v-model="programForm.name"
          v-validate="'required'"
          type="text"
          name="name"
          class="form-control"
        >
        <span
          v-show="errors.has('name')"
          class="error"
        >{{ errors.first('name') }}</span>
      </div>
      <div class="form-group">
        <label for="programDescription">{{ $t('description') }}</label>
        <textarea
          id="programDescription"
          v-model="programForm.description"
          name="description"
          class="form-control"
          rows="5"
        />
        <span
          v-show="errors.has('description')"
          class="error"
        >{{ errors.first('description') }}</span>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-5">
            <label for="programType">{{ $t('type') }} *</label>
            <select
              id="programType"
              v-model="programForm.type"
              v-validate="'required'"
              class="form-control"
              name="type"
            >
              <option value="">
                {{ $t('selectType') }}
              </option>
              <option
                v-for="type in programTypes"
                :key="type.id"
                :value="type.id"
              >
                {{ type.abbreviation }}
              </option>
            </select>
            <span
              v-show="errors.has('type')"
              class="error"
            >{{ errors.first('type') }}</span>
          </div>
        </div>
      </div>
      <div class="form-group">
        <label for="programLength">{{ $t('programLength') }}</label>
        <div class="row">
          <div class="col-md-5 program-length">
            <input
              id="programLength"
              v-model="programForm.length"
              type="number"
              name="length"
              class="form-control"
            >
            <select
              v-model="programForm.length_type"
              class="form-control"
            >
              <option value="days">
                Days
              </option>
              <option value="weeks">
                Weeks
              </option>
            </select>
          </div>
        </div>
        <span
          v-show="errors.has('length')"
          class="error"
        >{{ errors.first('length') }}</span>
      </div>
      <div class="form-group">
        <div class="row">
          <div class="col-md-8">
            <label for="programClinician">{{ $t('programClinician') }}{{ requiredInput }}</label>
            <clinicians-teams-selector
              ref="cliniciansTeamsSelector"
              v-model="selectedClinicians"
              v-validate="validationString"
              name="clinicians"
              :teams-enabled="true"
            />
            <input
              v-model="hasCliniciansSelected"
              v-validate="validationString"
              type="hidden"
              name="has_clinician_selected"
              :data-vv-as="$t('programClinician')"
            >
            <span
              v-show="errors.has('has_clinician_selected')"
              class="error"
            >{{ errors.first('has_clinician_selected') }}</span>
          </div>
        </div>
      </div>
      <div class="buttons">
        <button
          :disabled="submitDisabled"
          type="button"
          class="btn btn-outline-secondary"
          @click="submitProgram()"
        >
          {{ $t('saveAndClose') }}
        </button>
        <button
          :disabled="submitDisabled"
          type="button"
          class="btn btn-secondary"
          @click="submitProgram({addMeasure:true})"
        >
          {{ $t('continue') }}
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import CliniciansTeamsSelector from '@/components/common/CliniciansTeamsSelector'
export default {
  name: 'ClinicianProgramForm',
  components: {
    CliniciansTeamsSelector
  },
  props: ['action', 'program', 'programTypes'],
  data () {
    const selectedClinicians = {
      clinicians: [...this.program.program_clinicians.map(c => c.clinician_id) || []],
      teams: [...this.program.program_clinician_teams.map(ct => ct.clinician_team.id) || []]
    }
    return {
      programForm: Object.assign({}, this.program),
      selectedClinicians: selectedClinicians,
      hasCliniciansSelected: selectedClinicians.clinicians.length || selectedClinicians.teams.length ? true : null,
      submitState: false
    }
  },
  computed: {
    ...mapGetters({
      user: 'loggedInUser',
      clinicians: 'getClinicians',
      cliniciansLoading: 'getCliniciansLoading'
    }),
    validationString () {
      return this.action === 'update' && this.program.patients_in_program && (this.program.program_clinicians.length || this.program.program_clinician_teams.length) ? 'required' : ''
    },
    submitDisabled () {
      if (this.submitState || this.cliniciansLoading) {
        return true
      }

      return false
    },
    title () {
      return `${this.action}Program`
    },
    computedForm () {
      return Object.assign({}, this.programForm)
    },
    requiredInput () {
      return this.validationString ? ' *' : ''
    }
  },
  watch: {
    computedForm: {
      handler (n, o) {
        this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', Object.keys(this.programForm).some(field => n[field] !== o[field]))
      },
      deep: true
    },
    selectedClinicians (n, o) {
      this.hasCliniciansSelected = null
      if (n) {
        this.hasCliniciansSelected = n.clinicians.length || n.teams.length ? true : null
      }
      const newLength = n && n.all ? n.all.length : 0
      const oldLength = o && o.all ? o.all.length : 0
      this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', newLength !== oldLength)
    }
  },
  destroyed () {
    this.$store.dispatch('SET_ACTIVE_FORM_DIRTY', false)
  },
  methods: {
    submitProgram (params) {
      this.$validator.validateAll().then((valid) => {
        if (valid) {
          this.submitState = true
          this.programForm.addMeasure = params && params.addMeasure
          const data = { ...this.programForm }
          data.owner_id = this.user.id
          if (data.length_type === 'weeks') {
            data.length = data.length * 7
          }

          if (isNaN(data.length) || data.length === null) {
            delete data.length
          }

          data.clinicians = this.selectedClinicians ? [...this.selectedClinicians.clinicians] : []
          data.clinicians_teams = this.selectedClinicians ? [...this.selectedClinicians.teams] : []
          this.$emit('submitProgram', data)
          this.$validator.reset()
        }
      })
    }
  }
}
</script>
